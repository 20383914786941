import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7c9cc8c5 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _2f7b0f1c = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _935dd4fe = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _a22a06ba = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _e945084e = () => interopDefault(import('../pages/administration/bus_stops.vue' /* webpackChunkName: "pages/administration/bus_stops" */))
const _5a162356 = () => interopDefault(import('../pages/administration/buses.vue' /* webpackChunkName: "pages/administration/buses" */))
const _3e611b9c = () => interopDefault(import('../pages/administration/drivers.vue' /* webpackChunkName: "pages/administration/drivers" */))
const _23e0640c = () => interopDefault(import('../pages/administration/roads.vue' /* webpackChunkName: "pages/administration/roads" */))
const _35de942f = () => interopDefault(import('../pages/administration/users.vue' /* webpackChunkName: "pages/administration/users" */))
const _72227d50 = () => interopDefault(import('../pages/notifications/overview.vue' /* webpackChunkName: "pages/notifications/overview" */))
const _961814a0 = () => interopDefault(import('../pages/overview/buses.vue' /* webpackChunkName: "pages/overview/buses" */))
const _f1445cf0 = () => interopDefault(import('../pages/overview/daily_report.vue' /* webpackChunkName: "pages/overview/daily_report" */))
const _17d63ef2 = () => interopDefault(import('../pages/overview/passengers.vue' /* webpackChunkName: "pages/overview/passengers" */))
const _55a32315 = () => interopDefault(import('../pages/overview/reservations.vue' /* webpackChunkName: "pages/overview/reservations" */))
const _36a634f4 = () => interopDefault(import('../pages/system/agents.vue' /* webpackChunkName: "pages/system/agents" */))
const _285a0d84 = () => interopDefault(import('../pages/system/areas.vue' /* webpackChunkName: "pages/system/areas" */))
const _1c395531 = () => interopDefault(import('../pages/system/availabilities.vue' /* webpackChunkName: "pages/system/availabilities" */))
const _449383b7 = () => interopDefault(import('../pages/system/depots.vue' /* webpackChunkName: "pages/system/depots" */))
const _02da9f10 = () => interopDefault(import('../pages/system/holiday.vue' /* webpackChunkName: "pages/system/holiday" */))
const _23f40787 = () => interopDefault(import('../pages/system/operators.vue' /* webpackChunkName: "pages/system/operators" */))
const _581a7c7c = () => interopDefault(import('../pages/system/prices.vue' /* webpackChunkName: "pages/system/prices" */))
const _6e7413ca = () => interopDefault(import('../pages/system/statistics.vue' /* webpackChunkName: "pages/system/statistics" */))
const _2241c6aa = () => interopDefault(import('../pages/systemlog/overview.vue' /* webpackChunkName: "pages/systemlog/overview" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/forget_password/",
    component: _7c9cc8c5,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _2f7b0f1c,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _935dd4fe,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _a22a06ba,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/administration/bus_stops/",
    component: _e945084e,
    pathToRegexpOptions: {"strict":true},
    name: "administration-bus_stops"
  }, {
    path: "/administration/buses/",
    component: _5a162356,
    pathToRegexpOptions: {"strict":true},
    name: "administration-buses"
  }, {
    path: "/administration/drivers/",
    component: _3e611b9c,
    pathToRegexpOptions: {"strict":true},
    name: "administration-drivers"
  }, {
    path: "/administration/roads/",
    component: _23e0640c,
    pathToRegexpOptions: {"strict":true},
    name: "administration-roads"
  }, {
    path: "/administration/users/",
    component: _35de942f,
    pathToRegexpOptions: {"strict":true},
    name: "administration-users"
  }, {
    path: "/notifications/overview/",
    component: _72227d50,
    pathToRegexpOptions: {"strict":true},
    name: "notifications-overview"
  }, {
    path: "/overview/buses/",
    component: _961814a0,
    pathToRegexpOptions: {"strict":true},
    name: "overview-buses"
  }, {
    path: "/overview/daily_report/",
    component: _f1445cf0,
    pathToRegexpOptions: {"strict":true},
    name: "overview-daily_report"
  }, {
    path: "/overview/passengers/",
    component: _17d63ef2,
    pathToRegexpOptions: {"strict":true},
    name: "overview-passengers"
  }, {
    path: "/overview/reservations/",
    component: _55a32315,
    pathToRegexpOptions: {"strict":true},
    name: "overview-reservations"
  }, {
    path: "/system/agents/",
    component: _36a634f4,
    pathToRegexpOptions: {"strict":true},
    name: "system-agents"
  }, {
    path: "/system/areas/",
    component: _285a0d84,
    pathToRegexpOptions: {"strict":true},
    name: "system-areas"
  }, {
    path: "/system/availabilities/",
    component: _1c395531,
    pathToRegexpOptions: {"strict":true},
    name: "system-availabilities"
  }, {
    path: "/system/depots/",
    component: _449383b7,
    pathToRegexpOptions: {"strict":true},
    name: "system-depots"
  }, {
    path: "/system/holiday/",
    component: _02da9f10,
    pathToRegexpOptions: {"strict":true},
    name: "system-holiday"
  }, {
    path: "/system/operators/",
    component: _23f40787,
    pathToRegexpOptions: {"strict":true},
    name: "system-operators"
  }, {
    path: "/system/prices/",
    component: _581a7c7c,
    pathToRegexpOptions: {"strict":true},
    name: "system-prices"
  }, {
    path: "/system/statistics/",
    component: _6e7413ca,
    pathToRegexpOptions: {"strict":true},
    name: "system-statistics"
  }, {
    path: "/systemlog/overview/",
    component: _2241c6aa,
    pathToRegexpOptions: {"strict":true},
    name: "systemlog-overview"
  }, {
    path: "/",
    redirect: "/overview/buses/",
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
